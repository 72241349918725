<!-- eslint-disable -->
<template>
    <div class="row">
        <div class="col-12 col-xl-12 grid-margin stretch-card">
            <div class="card overflow-hidden">
                <div class="card-body">
                    <div class="d-flex justify-content-between align-items-center flex-wrap">

                        <div class="me-auto">
                        </div>
                        <div class="me-auto">
                        </div>
                        <div class="d-flex align-items-center flex-wrap text-nowrap">
                            <div class="p-float-label">
                                <Calendar id="date" v-model="date" :showIcon="true" />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div> <!-- row -->

    <!-- datatable -->
    <div class="row">

        <div class="col-md-6 grid-margin stretch-card">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex justify-content-between align-items-center flex-wrap">
                        <h6 class="card-title">Famille</h6>
                        <div class="d-flex">
                            <div class="me-4 btn" @click="exportCSV($event)">
                                <i class="pi pi-copy text-primary icon-xl" style="font-size: 1.5rem"></i>
                                <h5 class="text-center fw-light">Copy</h5>
                            </div>
                            <download-excel :data="items1" :fields="json_fields">
                                <div class="me-4 btn">
                                    <i class="pi pi-external-link text-primary icon-xl" style="font-size: 1.5rem"></i>
                                    <h5 class="text-center fw-light">Exc</h5>
                                </div>
                            </download-excel>
                            <div class="btn" @click="printDocument1('')">
                                <i class="pi pi-file-pdf text-primary icon-xl" style="font-size: 1.5rem"></i>
                                <h5 class="text-center fw-light">PDF</h5>
                            </div>
                        </div>
                    </div>
                    <span class="p-input-icon-left">
                        <i class="pi pi-search" />
                        <InputText type="text" v-model="searchValue" placeholder="Search" />
                    </span>
                    <div></div>
                    <EasyDataTable id="divToPrint" ref="dt" :headers="headers" :items="items1" alternating
                        buttons-pagination theme-color="#42668F" :search-value="searchValue">
                        <template #expand="item">
                            <div class="row">
                                <div class="col-md-12 grid-margin stretch-card">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="table-responsive">
                                                <table class="tableClass table table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th>Name</th>
                                                            <th>Qte</th>
                                                            <th>Ratio1</th>
                                                            <th>C.A</th>
                                                            <th>Ratio2</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(el, index) in items" :key="index">
                                                            <td
                                                                v-if="el.family === item.family && item.family !== 'Total'">
                                                                {{ el.name }}</td>
                                                            <td
                                                                v-if="el.family === item.family && item.family !== 'Total'">
                                                                {{ el.qte }}</td>
                                                            <td
                                                                v-if="el.family === item.family && item.family !== 'Total'">
                                                                {{ `${+Number(el.qte * 100 / item.qte).toFixed(2)} %` }}
                                                            </td>
                                                            <td
                                                                v-if="el.family === item.family && item.family !== 'Total'">
                                                                {{ `${el.ca} €` }}</td>
                                                            <td
                                                                v-if="el.family === item.family && item.family !== 'Total'">
                                                                {{ `${+Number(el.ca * 100 / item.ca.substr(0,
                                                                    item.ca.length
                                                                    - 2)).toFixed(2)} %`
                                                                }}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </EasyDataTable>
                    <!-- testestestsetestest -->
                </div>
            </div>
        </div>

        <div class="col-md-6 grid-margin stretch-card">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex justify-content-between align-items-center flex-wrap">
                        <h6 class="card-title">TVA</h6>
                        <div class="d-flex">
                            <div class="me-4 btn" @click="exportCSV($event)">
                                <i class="pi pi-copy text-primary icon-xl" style="font-size: 1.5rem"></i>
                                <h5 class="text-center fw-light">Copy</h5>
                            </div>
                            <download-excel :data="tva.lenght != 0 ? tva : []" :fields="json_fields1">
                                <div class="me-4 btn">
                                    <i class="pi pi-external-link text-primary icon-xl" style="font-size: 1.5rem"></i>
                                    <h5 class="text-center fw-light">Exc</h5>
                                </div>
                            </download-excel>
                            <div class="btn" @click="printDocument('1')">
                                <i class="pi pi-file-pdf text-primary icon-xl" style="font-size: 1.5rem"></i>
                                <h5 class="text-center fw-light">PDF</h5>
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <DataTable showGridlines id="divToPrint1" :value="tva.lenght != 0 ? tva : []" ref="dt"
                            class="p-datatable-lg table" :paginator="true" :rows="10" sortMode="multiple" dataKey="id"
                            :rowHover="true" v-model:filters="filters1" filterDisplay="menu" :loading="false"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            :rowsPerPageOptions="[10, 25, 50]"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                            responsiveLayout="scroll">
                            <template #header>
                                <div class="d-flex align-items-center">
                                    <span class="p-input-icon-left">
                                        <i class="pi pi-search mx-3" />
                                        <InputText v-model="filters1['global'].value" placeholder="Keyword Search" />
                                    </span>
                                </div>
                            </template>
                            <template #empty>
                                No TVA found.
                            </template>
                            <template #loading>
                                Loading TVA data. Please wait.
                            </template>
                            <Column v-for="col of columns1" :field="col.field" :header="col.header" :key="col.field"
                                :sortable="true">
                            </Column>
                            <template #footer>
                                In total there are {{ tva ? tva.length : 0 }} TVA.
                            </template>
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-6 grid-margin stretch-card">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex justify-content-between align-items-center flex-wrap">
                        <h6 class="card-title">Statistiques</h6>
                        <div class="d-flex">
                            <div class="me-4 btn" @click="exportCSV($event)">
                                <i class="pi pi-copy text-primary icon-xl" style="font-size: 1.5rem"></i>
                                <h5 class="text-center fw-light">Copy</h5>
                            </div>
                            <download-excel :data="statistiquesDashboard.lenght != 0 ? statistiquesDashboard : []"
                                :fields="json_fields2">
                                <div class="me-4 btn">
                                    <i class="pi pi-external-link text-primary icon-xl" style="font-size: 1.5rem"></i>
                                    <h5 class="text-center fw-light">Exc</h5>
                                </div>
                            </download-excel>
                            <div class="btn" @click="printDocument('2')">
                                <i class="pi pi-file-pdf text-primary icon-xl" style="font-size: 1.5rem"></i>
                                <h5 class="text-center fw-light">PDF</h5>
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <DataTable showGridlines id="divToPrint2"
                            :value="statistiquesDashboard.lenght != 0 ? statistiquesDashboard : []" ref="dt"
                            class="p-datatable-lg table" :paginator="true" :rows="10" sortMode="multiple" dataKey="id"
                            :rowHover="true" v-model:filters="filters2" filterDisplay="menu" :loading="false"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            :rowsPerPageOptions="[10, 25, 50]"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                            responsiveLayout="scroll">
                            <template #header>
                                <div class="d-flex align-items-center">
                                    <span class="p-input-icon-left">
                                        <i class="pi pi-search mx-3" />
                                        <InputText v-model="filters2['global'].value" placeholder="Keyword Search" />
                                    </span>
                                </div>
                            </template>
                            <template #empty>
                                No statistiques found.
                            </template>
                            <template #loading>
                                Loading statistiques data. Please wait.
                            </template>
                            <Column v-for="col of columns2" :field="col.field" :header="col.header" :key="col.field"
                                :sortable="true">
                            </Column>
                            <template #footer>
                                In total there are {{ statistiques ? statistiques.length : 0 }} statistiques.
                            </template>
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-6 grid-margin stretch-card">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex justify-content-between align-items-center flex-wrap">
                        <h6 class="card-title">STATISTIQUES DES CAISSES</h6>
                        <div class="d-flex">
                            <div class="me-4 btn" @click="exportCSV($event)">
                                <i class="pi pi-copy text-primary icon-xl" style="font-size: 1.5rem"></i>
                                <h5 class="text-center fw-light">Copy</h5>
                            </div>
                            <download-excel :data="statistiquesDashboard.lenght != 0 ? statistiquesDashboard : []"
                                :fields="json_fields3">
                                <div class="me-4 btn">
                                    <i class="pi pi-external-link text-primary icon-xl" style="font-size: 1.5rem"></i>
                                    <h5 class="text-center fw-light">Exc</h5>
                                </div>
                            </download-excel>
                            <div class="btn" @click="printDocument('3')">
                                <i class="pi pi-file-pdf text-primary icon-xl" style="font-size: 1.5rem"></i>
                                <h5 class="text-center fw-light">PDF</h5>
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <DataTable showGridlines id="divToPrint3"
                            :value="statistiquesDashboard.lenght != 0 ? statistiquesDashboard : []" ref="dt"
                            class="p-datatable-lg table" :paginator="true" :rows="10" sortMode="multiple" dataKey="id"
                            :rowHover="true" v-model:filters="filters3" filterDisplay="menu" :loading="false"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            :rowsPerPageOptions="[10, 25, 50]"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                            responsiveLayout="scroll">
                            <template #header>
                                <div class="d-flex align-items-center">
                                    <span class="p-input-icon-left">
                                        <i class="pi pi-search mx-3" />
                                        <InputText v-model="filters3['global'].value" placeholder="Keyword Search" />
                                    </span>
                                </div>
                            </template>
                            <template #empty>
                                No statistiques caisse found.
                            </template>
                            <template #loading>
                                Loading statistiques caisse data. Please wait.
                            </template>
                            <Column v-for="col of columns3" :field="col.field" :header="col.header" :key="col.field"
                                :sortable="true">
                            </Column>
                            <template #footer>
                                In total there are {{ statistiquescaisse ? statistiquescaisse.length : 0 }} statistiques
                                caisse.
                            </template>
                        </DataTable>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <!-- Chart -->
        <div class="col-md-6 grid-margin stretch-card">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex justify-content-between align-items-center flex-wrap">
                        <h6 class="card-title">Family Chart</h6>
                    </div>
                    <!-- chart here -->
                    <Chart type="line" :data="basicData" :options="basicOptions" />
                </div>
            </div>
        </div>
        <!-- Chart -->
        <div class="col-md-6 grid-margin stretch-card">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex justify-content-between align-items-center flex-wrap">
                        <h6 class="card-title">TVA Chart</h6>
                    </div>
                    <!-- chart here -->
                    <Chart type="line" :data="basicData" :options="basicOptions" />
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-6 grid-margin stretch-card">
            <div class="card">
                <div class="card-body">
                    <!-- Chart -->
                    <div class="grid-margin stretch-card">
                        <div class="card">
                            <div class="card-body">
                                <h6 class="card-title">Paiements</h6>
                                <!-- <div id="apexBar"></div> -->
                                <Chart type="bar" :data="basicDataBar" :options="basicOptionsBar" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6 grid-margin stretch-card">
            <div class="card">
                <div class="card-body">
                    <!-- Chart -->
                    <div class="grid-margin stretch-card">
                        <div class="card">
                            <div class="card-body">
                                <h6 class="card-title">CHIFFRE D’AFFAIRES BRUT HORAIRE</h6>
                                <!-- <div id="apexArea"></div> -->
                                <Chart type="line" :data="lineStylesData" :options="basicOptionsStyle" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</template>
<!-- eslint-disable -->
<script>
/* eslint-disable */
import { mapActions, mapGetters } from "vuex";
import { FilterMatchMode } from "primevue/api";
import pdfMake from "pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import htmlToPdfmake from "html-to-pdfmake";

export default {
    name: "ReportView",
    computed: {
        ...mapGetters({ res: "StateRes" }),
    },
    mounted() {
        this.date = this.currentDateTime();
    },
    watch: {
        date(value) {
            /* eslint-disable-next-line */
            this.get(this.$dayjs(value).format("MM-DD-YYYY"), this.$dayjs(value).format("MM-DD-YYYY"), "day", this.res);
            //this.get(value, value, "day", this.res);
        },
    },
    data() {
        return {
            /* eslint-disable */
            headers: [
                { text: "Family", value: "family", sortable: true },
                { text: "Qte", value: "qte", sortable: true },
                { text: "Ratio1", value: "ratio1", sortable: true },
                { text: "C.A", value: "ca", sortable: true },
                { text: "Ratio2", value: "ratio2", sortable: true },
            ],
            headers1: [
                { text: "Name", value: "name", sortable: true },
                { text: "Qte", value: "qte", sortable: true },
                { text: "Ratio1", value: "ratio1", sortable: true },
                { text: "C.A", value: "ca", sortable: true, width: 50 },
                { text: "Ratio2", value: "ratio2", sortable: true },
            ],
            statistiquesDashboard: [],
            items: [],
            items1: [],
            searchValue: "",
            tva: [],
            statistiques: [],
            statistiquescaisse: [],
            columns: null,
            columns1: null,
            columns2: null,
            columns3: null,
            date: null,
            show: true,
            filters: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
            },
            filters1: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
            },
            filters2: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
            },
            filters3: {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
            },
            json_fields: {
                "Family": "family",
                "Qte": "qte",
                "Ratio1": "ratio1",
                "C.A": "ca",
                "Ratio2": "ratio2",
            },
            json_fields1: {
                "TVA Category": "category",
                "TVA": "tva",
                "HT": "ht",
                "TTC": "ttc",
            },
            json_fields2: {
                "Type": "family",
                "Qte": "quantity",
                "Moyenne": "avg",
            },
            json_fields3: {
                "Type": "family",
                "Qte": "quantity",
                "C.A": "value",
            },
            // chart 
            basicData: {
                /* eslint-disable */
                labels: ["January", "February", "March", "April", "May", "June", "July"],
                datasets: [
                    {
                        label: "Revenue",
                        data: [65, 59, 80, 81, 56, 55, 40],
                        fill: false,
                        borderColor: "#42A5F5",
                        tension: 0.4,
                    },
                ],
            },
            basicOptions: {
                plugins: {
                    legend: {
                        labels: {
                            color: "#495057",
                        },
                    },
                },
                scales: {
                    x: {
                        ticks: {
                            color: "#495057",
                        },
                        grid: {
                            color: "#ebedef",
                        },
                    },
                    y: {
                        ticks: {
                            color: "#495057",
                        },
                        grid: {
                            color: "#ebedef",
                        },
                    },
                },
            },
            basicDataBar: {
                labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                datasets: [
                    {
                        label: 'My First dataset',
                        backgroundColor: '#42A5F5',
                        data: [65, 59, 80, 81, 56, 55, 40]
                    },
                ]
            },
            basicOptionsBar: {
                plugins: {
                    legend: {
                        labels: {
                            color: '#495057'
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    },
                    y: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    }
                }
            },

            lineStylesData: {
                labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                datasets: [
                    {
                        label: 'Total Views',
                        data: [65, 59, 80, 81, 56, 55, 40],
                        fill: true,
                        tension: .4,
                        borderColor: '#42A5F5'
                    },
                    {
                        label: 'Unique Views',
                        data: [12, 51, 62, 33, 21, 62, 45],
                        fill: true,
                        borderColor: '#FFA726',
                        tension: .4,
                        backgroundColor: 'rgba(255,167,38,0.2)'
                    }
                ]
            },
            basicOptionsStyle: {
                plugins: {
                    legend: {
                        labels: {
                            color: '#495057'
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    },
                    y: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    }
                }
            },
        };
    },
    created() {
        this.columns = [
            { field: "family", header: "Family" },
            { field: "qte", header: "Qte" },
            { field: "ratio1", header: "Ratio1" },
            { field: "ca", header: "C.A" },
            { field: "ratio2", header: "Ratio2" },
        ];
        this.columns1 = [
            { field: "category", header: "TVA Category" },
            { field: "tva", header: "TVA" },
            { field: "ht", header: "HT" },
            { field: "ttc", header: "TTC" },
        ];
        this.columns2 = [
            { field: "family", header: "Type" },
            { field: "quantity", header: "Qte" },
            { field: "avg", header: "Moyenne" },
        ];
        this.columns3 = [
            { field: "family", header: "Type" },
            { field: "quantity", header: "Qte" },
            { field: "value", header: "C.A" },
        ];
    },
    methods: {
        ...mapActions(["getAllByDate"]),
        currentDateTime() {
            const current = new Date(Date.now());
            return current;
            //const current = new Date();
            //const dateTime = this.$dayjs(current).format("MM-DD-YYYY");
            //return dateTime;
        },
        async get(date, date1, check, res) {

            try {
                let ENTREEqte = 0, ENTREEca = 0, NONLIEqte = 0, NONLIEca = 0, BOISSONSFROIDESqte = 0, BOISSONSFROIDESca = 0, POISSONSqte = 0, POISSONSca = 0, VINROUGEqte = 0, VINROUGEca = 0, SALADEqte = 0, SALADEca = 0, ALADEqte = 0, ALADEca = 0, VINBLANCqte = 0, VINBLANCca = 0, VIANDESqte = 0, VIANDESca = 0, COMMENTAIRESqte = 0, COMMENTAIRESca = 0, APERITIFSqte = 0, APERITIFSca = 0, BOISSONSCHAUDESqte = 0, BOISSONSCHAUDESca = 0, PATESqte = 0, PATESca = 0, Totalqte = 0, Totalca = 0;
                this.items = await this.getAllByDate({ date, date1, check, url: "famille", res });
                this.statistiquesDashboard = await this.getAllByDate({ date, date1, check, url: "statistiquesDashboard", res });
                this.items.map(el => {
                    Totalqte += +el.qte;
                    Totalca += +el.ca;
                    switch (el.family) {
                        case "ENTREE":
                            {
                                ENTREEqte += +el.qte;
                                ENTREEca += +el.ca;
                            }
                            break;
                        case "NON LIE":
                            {
                                NONLIEqte += +el.qte;
                                NONLIEca += +el.ca;
                            }
                            break;
                        case "BOISSONS FROIDES":
                            {
                                BOISSONSFROIDESqte += +el.qte;
                                BOISSONSFROIDESca += +el.ca;
                            }
                            break;
                        case "POISSONS":
                            {
                                POISSONSqte += +el.qte;
                                POISSONSca += +el.ca;
                            }
                            break;
                        case "VIN ROUGE":
                            {
                                VINROUGEqte += +el.qte;
                                VINROUGEca += +el.ca;
                            }
                            break;
                        case "SALADE":
                            {
                                SALADEqte += +el.qte;
                                SALADEca += +el.ca;
                            }
                            break;
                        case "ALADE":
                            {
                                ALADEqte += +el.qte;
                                ALADEca += +el.ca;
                            }
                            break;
                        case "VIN BLANC":
                            {
                                VINBLANCqte += +el.qte;
                                VINBLANCca += +el.ca;
                            }
                            break;
                        case "VIANDES":
                            {
                                VIANDESqte += +el.qte;
                                VIANDESca += +el.ca;
                            }
                            break;
                        case "COMMENTAIRES":
                            {
                                COMMENTAIRESqte += +el.qte;
                                COMMENTAIRESca += +el.ca;
                            }
                            break;
                        case "APERITIFS":
                            {
                                APERITIFSqte += +el.qte;
                                APERITIFSca += +el.ca;
                            }
                            break;
                        case "BOISSONS CHAUDES":
                            {
                                BOISSONSCHAUDESqte += +el.qte;
                                BOISSONSCHAUDESca += +el.ca;
                            }
                            break;
                        case "PATES":
                            {
                                PATESqte += +el.qte;
                                PATESca += +el.ca;
                            }
                            break;
                        default:
                            break;
                    }
                });
                this.items1 = [
                    { family: "ENTREE", qte: ENTREEqte, ratio1: `${Number(ENTREEqte * 100 / Totalqte).toFixed(2)} %` != "NaN %" ? `${Number(ENTREEqte * 100 / Totalqte).toFixed(2)} %` : "0 %", ca: `${Number(ENTREEca).toFixed(2)} €`, ratio2: `${Number(ENTREEca * 100 / Totalca).toFixed(2)} %` != "NaN %" ? `${Number(ENTREEca * 100 / Totalca).toFixed(2)} %` : "0 %" },
                    { family: "NON LIE", qte: NONLIEqte, ratio1: `${Number(NONLIEqte * 100 / Totalqte).toFixed(2)} %` != "NaN %" ? `${Number(NONLIEqte * 100 / Totalqte).toFixed(2)} %` : "0 %", ca: `${Number(NONLIEca).toFixed(2)} €`, ratio2: `${Number(NONLIEca * 100 / Totalca).toFixed(2)} %` != "NaN %" ? `${Number(NONLIEca * 100 / Totalca).toFixed(2)} %` : "0 %" },
                    { family: "BOISSONS FROIDES", qte: BOISSONSFROIDESqte, ratio1: `${Number(BOISSONSFROIDESqte * 100 / Totalqte).toFixed(2)} %` != "NaN %" ? `${Number(BOISSONSFROIDESqte * 100 / Totalqte).toFixed(2)} %` : "0 %", ca: `${Number(BOISSONSFROIDESca).toFixed(2)} €`, ratio2: `${Number(BOISSONSFROIDESca * 100 / Totalca).toFixed(2)} %` != "NaN %" ? `${Number(BOISSONSFROIDESca * 100 / Totalca).toFixed(2)} %` : "0 %" },
                    { family: "POISSONS", qte: POISSONSqte, ratio1: `${Number(POISSONSqte * 100 / Totalqte).toFixed(2)} %` != "NaN %" ? `${Number(POISSONSqte * 100 / Totalqte).toFixed(2)} %` : "0 %", ca: `${Number(POISSONSca).toFixed(2)} €`, ratio2: `${Number(POISSONSca * 100 / Totalca).toFixed(2)} %` != "NaN %" ? `${Number(POISSONSca * 100 / Totalca).toFixed(2)} %` : "0 %" },
                    { family: "VIN ROUGE", qte: VINROUGEqte, ratio1: `${Number(VINROUGEqte * 100 / Totalqte).toFixed(2)} %` != "NaN %" ? `${Number(VINROUGEqte * 100 / Totalqte).toFixed(2)} %` : "0 %", ca: `${Number(VINROUGEca).toFixed(2)} €`, ratio2: `${Number(VINROUGEca * 100 / Totalca).toFixed(2)} %` != "NaN %" ? `${Number(VINROUGEca * 100 / Totalca).toFixed(2)} %` : "0 %" },
                    { family: "SALADE", qte: SALADEqte, ratio1: `${Number(SALADEqte * 100 / Totalqte).toFixed(2)} %` != "NaN %" ? `${Number(SALADEqte * 100 / Totalqte).toFixed(2)} %` : "0 %", ca: `${Number(SALADEca).toFixed(2)} €`, ratio2: `${Number(SALADEca * 100 / Totalca).toFixed(2)} %` != "NaN %" ? `${Number(SALADEca * 100 / Totalca).toFixed(2)} %` : "0 %" },
                    { family: "ALADE", qte: ALADEqte, ratio1: `${Number(ALADEqte * 100 / Totalqte).toFixed(2)} %` != "NaN %" ? `${Number(ALADEqte * 100 / Totalqte).toFixed(2)} %` : "0 %", ca: `${Number(ALADEca).toFixed(2)} €`, ratio2: `${Number(ALADEca * 100 / Totalca).toFixed(2)} %` != "NaN %" ? `${Number(ALADEca * 100 / Totalca).toFixed(2)} %` : "0 %" },
                    { family: "VIN BLANC", qte: VINBLANCqte, ratio1: `${Number(VINBLANCqte * 100 / Totalqte).toFixed(2)} %` != "NaN %" ? `${Number(VINBLANCqte * 100 / Totalqte).toFixed(2)} %` : "0 %", ca: `${Number(VINBLANCca).toFixed(2)} €`, ratio2: `${Number(VINBLANCca * 100 / Totalca).toFixed(2)} %` != "NaN %" ? `${Number(VINBLANCca * 100 / Totalca).toFixed(2)} %` : "0 %" },
                    { family: "VIANDES", qte: VIANDESqte, ratio1: `${Number(VIANDESqte * 100 / Totalqte).toFixed(2)} %` != "NaN %" ? `${Number(VIANDESqte * 100 / Totalqte).toFixed(2)} %` : "0 %", ca: `${Number(VIANDESca).toFixed(2)} €`, ratio2: `${Number(VIANDESca * 100 / Totalca).toFixed(2)} %` != "NaN %" ? `${Number(VIANDESca * 100 / Totalca).toFixed(2)} %` : "0 %" },
                    { family: "COMMENTAIRES", qte: COMMENTAIRESqte, ratio1: `${Number(COMMENTAIRESqte * 100 / Totalqte).toFixed(2)} %` != "NaN %" ? `${Number(COMMENTAIRESqte * 100 / Totalqte).toFixed(2)} %` : "0 %", ca: `${Number(COMMENTAIRESca).toFixed(2)} €`, ratio2: `${Number(COMMENTAIRESca * 100 / Totalca).toFixed(2)} %` != "NaN %" ? `${Number(COMMENTAIRESca * 100 / Totalca).toFixed(2)} %` : "0 %" },
                    { family: "APERITIFS", qte: APERITIFSqte, ratio1: `${Number(APERITIFSqte * 100 / Totalqte).toFixed(2)} %` != "NaN %" ? `${Number(APERITIFSqte * 100 / Totalqte).toFixed(2)} %` : "0 %", ca: `${Number(APERITIFSca).toFixed(2)} €`, ratio2: `${Number(APERITIFSca * 100 / Totalca).toFixed(2)} %` != "NaN %" ? `${Number(APERITIFSca * 100 / Totalca).toFixed(2)} %` : "0 %" },
                    { family: "BOISSONS CHAUDES", qte: BOISSONSCHAUDESqte, ratio1: `${Number(BOISSONSCHAUDESqte * 100 / Totalqte).toFixed(2)} %` != "NaN %" ? `${Number(BOISSONSCHAUDESqte * 100 / Totalqte).toFixed(2)} %` : "0 %", ca: `${Number(BOISSONSCHAUDESca).toFixed(2)} €`, ratio2: `${Number(BOISSONSCHAUDESca * 100 / Totalca).toFixed(2)} %` != "NaN %" ? `${Number(BOISSONSCHAUDESca * 100 / Totalca).toFixed(2)} %` : "0 %" },
                    { family: "PATES", qte: PATESqte, ratio1: `${Number(PATESqte * 100 / Totalqte).toFixed(2)} %` != "NaN %" ? `${Number(PATESqte * 100 / Totalqte).toFixed(2)} %` : "0 %", ca: `${Number(PATESca).toFixed(2)} €`, ratio2: `${Number(PATESca * 100 / Totalca).toFixed(2)} %` != "NaN %" ? `${Number(PATESca * 100 / Totalca).toFixed(2)} %` : "0 %" },
                    { family: "Total", qte: Totalqte, ratio1: "100%", ca: `${Number(Totalca).toFixed(2)} €`, ratio2: "100%", },
                ]
                this.tva = await this.getAllByDate({ date, date1, check, url: "tva", res });
                this.statistiques = await this.getAllByDate({ date, date1, check, url: "statistiques", res });
                this.statistiquescaisse = await this.getAllByDate({ date, date1, check, url: "statistiquescaisse", res });
                this.show = true;
            } catch (error) {
                this.show = false;
            }
        },
        exportCSV() {
            this.$refs.dt.exportCSV();
        },
        printDocument(id) {
            const pdfTable = document.getElementById(`divToPrint${id}`);
            let html = htmlToPdfmake(pdfTable.innerHTML);
            html = html.splice(0, 2);
            const documentDefinition = { content: html };
            pdfMake.vfs = pdfFonts.pdfMake.vfs;
            pdfMake.createPdf(documentDefinition).open();
        },
        printDocument1(id) {
            const pdfTable = document.getElementById(`divToPrint${id}`);
            let html = htmlToPdfmake(pdfTable.innerHTML);
            html = html.splice(0, 1);
            const documentDefinition = { content: html };
            pdfMake.vfs = pdfFonts.pdfMake.vfs;
            pdfMake.createPdf(documentDefinition).open();
        }
    },
};
</script>
<!-- eslint-disable -->
<style lang="scss" scoped>
::v-deep(.p-paginator) {
    .p-paginator-current {
        margin-left: auto;
    }
}

::v-deep(.p-progressbar) {
    height: .5rem;
    background-color: #D8DADC;

    .p-progressbar-value {
        background-color: #607D8B;
    }
}

::v-deep(.p-datepicker) {
    min-width: 25rem;

    td {
        font-weight: 400;
    }
}

::v-deep(.p-datatable.p-datatable-customers) {
    .p-datatable-header {
        padding: 1rem;
        text-align: left;
        font-size: 1.5rem;
    }

    .p-paginator {
        padding: 1rem;
    }

    .p-datatable-thead>tr>th {
        text-align: left;
    }

    .p-datatable-tbody>tr>td {
        cursor: auto;
    }

    .p-dropdown-label:not(.p-placeholder) {
        text-transform: uppercase;
    }
}

.tableClass {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
}

.tableClass th,
.tableClass td {
    text-align: left;
    width: 25%;
}

.tableClass tbody {
    display: block;
    overflow: auto;
    height: 200px;
}

.tableClass thead {
    width: 100%;
    display: table;
}
</style>