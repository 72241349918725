<!-- eslint-disable -->
<script setup>
import Paginationcomt from "@/components/CompPagination.vue";
//import ButtonDates from "@/components/ButtonDates.vue";
import CardSearch from "@/components/CardSearch.vue";

</script>
<!-- eslint-disable -->
<template>
    <!-- <ButtonDates /> -->
    <CardSearch v-if="currentSite" @search="callFunctions" :operation="true" :etat="true" :caisse="true"
        :vendeur="true" />
    <div class="row">
        <div class="col-md-12 grid-margin stretch-card">
            <div class="card ">
                <div class="card-header">
                    <h3 class="card-title text-left"> Tickets </h3>
                </div>
                <div v-if="ticketsMongoDB.length > 0" class="card-body">

                    <div class="row mb-3 mt-3">
                        <div class="col-md-10">
                            <span> Showing <b>{{ pagination.data.showing_from }}</b> to <b>{{ pagination.data.showing_to
                                    }}</b> of
                                <b>{{ pagination.data.total_items }}</b> entries</span>

                            <p><b>page</b> : {{ pagination.data.current_page }} </p>
                        </div>
                        <div class="col-md-2">
                            <select v-model="pagination.per_page" @change="fetchTickets()"
                                class="form-select btn-outline-primary dropdown-toggle">
                                <option value="10">10</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="300">300</option>
                                <option value="500">500</option>
                            </select>
                        </div>
                    </div>
                    <div class="table-responsive tableClassss">

                        <DataTable showGridlines stripedRows :value="ticketsMongoDB">

                            <Column field="NumTicket" header="N° ticket" sortable>
                                <template #body="slotProps">
                                    <span v-if="slotProps.data?.NumTicket != '0'">
                                        Ticket N°{{ slotProps.data?.NumTicket }}
                                    </span>
                                </template>
                            </Column>
                            <Column field="TableNumber" header="N° Table" sortable>
                                <template #body="slotProps">
                                    <span v-if="slotProps.data?.TableNumber">
                                        Table N°{{ slotProps.data?.TableNumber }}
                                    </span>
                                </template>
                            </Column>

                            <Column field="LibVendeur" header="Vendeur" sortable>
                                <template #body="slotProps">
                                    {{ slotProps.data.LibVendeur }}
                                </template>
                            </Column>

                            <Column field="Horodatage" header="Date" sortable>
                                <template #body="slotProps">
                                    {{ $dayjs(slotProps.data?.Horodatage)?.utc().format("MM/DD/YYYY HH:mm:ss") }}

                                </template>
                            </Column>

                            <Column field="MontantTTC" header="Montant TTC" sortable>
                                <template #body="slotProps">
                                    {{ slotProps.data.MontantTTC.toFixed(2) }} €
                                </template>
                            </Column>
                            <Column field="Operation" header="Opération">
                                <template #body="slotProps">
                                    <span class="table-tag"
                                        :style="{ backgroundColor: operationColors[0][slotProps.data.Operation] }">
                                        {{ slotProps.data.Operation }}
                                    </span>

                                </template>
                            </Column>
                            <Column field="_id" header="Détails">
                                <template #body="slotProps">
                                    <button class="btn btn-info btn-icon-text mx-2 mb-3 mb-md-0"
                                        @click="VoirDetailsTicket(slotProps.data?._id)">
                                        Voir
                                    </button>

                                </template>
                            </Column>
                        </DataTable>
                    </div>
                    <Paginationcomt :pagination="pagination.data" @paginate="fetchTickets()" :offset="4">
                    </Paginationcomt>
                </div>
                <div v-else class="card-body">
                    <datanotfound :text="$t(`content.message.datanotfound`)" />
                </div>
            </div>
        </div>
    </div>
</template>
<!-- eslint-disable -->
<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

export default {
    components: { VueDatePicker },
    name: "mongoDATA",
    watch: {
        'datePicker.date.range'(newDate, oldDate) {
            this.callFunctions(this.datePicker.date.from, this.datePicker.date.to, this.datePicker.defaultDates.type);
        }
    },
    data() {
        return {
            operationColors: [
                {
                    'VENTE': '',
                    'TRANSFERT': '#0be80b',
                    'TRANSFERT-TABLE': '#0be80b',
                    'ANNULATION': '#bbb',
                    'ANNULLER': '#fb6060',
                    'JUSTIFICATIF': 'rgb(201, 186, 255)',
                    'INTERNE': 'rgb(255, 182, 89)',
                }
            ],
            tickets_mongoDB: [],
            pagination: {
                page: 0,
                limit: 4,
                skip: 0,
                per_page: 100,
                data: [],
            },
            sort: {
                MontantTTC: {
                    sortOrder: 1,
                },
                Horodatage: {
                    sortOrder: 1,
                }
            },
            ticketSortBy: {
                Horodatage: -1
            }

        };
    },
    mounted() {
    },
    methods: {

        sortData(field) {
            if (field === 'MontantTTC') {
                this.sort.MontantTTC.sortOrder = this.sort.MontantTTC.sortOrder === 1 ? -1 : 1
            }
            if (field === 'Horodatage') {
                this.sort.Horodatage.sortOrder = this.sort.Horodatage.sortOrder === 1 ? -1 : 1
            }
            this.ticketSortBy = {
                MontantTTC: this.sort.MontantTTC.sortOrder,
                Horodatage: this.sort.Horodatage.sortOrder,
            }
            this.fetchTickets()
        },
        ...mapMutations(["setTicketID"]),
        ...mapActions(["getTicketsByVendeurDates"]),
        async fetchTickets() {
            let from = this.datePicker.date.from
            window.scrollTo(0, 0);
            let current_page = this.pagination.data.current_page
                ? this.pagination.data.current_page
                : 1;

            let results = await this.getTicketsByVendeurDates({
                url: "entetes/tickets",
                page: current_page,
                skip: this.pagination.skip,
                limit: this.pagination.per_page,
                range: this.datePicker.date.range,
                vendeur: "vendeur",
                site: this.currentSite?._id,
                WhereLibInArray: [
                    { LibValeur: this.globalSearch?.SelectedLibVendeurs?.length ? this.globalSearch?.SelectedLibVendeurs : 'vendeur', LibKey: 'LibVendeur' },
                    { LibValeur: this.globalSearch?.SelectedLibCaisses?.length ? this.globalSearch?.SelectedLibCaisses : 'caisse', LibKey: 'LibCaisse' },
                    { LibValeur: this.globalSearch?.SelectedOperations?.length ? this.globalSearch?.SelectedOperations : null, LibKey: 'Operation' },
                    { LibValeur: this.globalSearch?.SelectedEtats?.length ? this.globalSearch?.SelectedEtats : null, LibKey: 'Etat' },
                ],
                WhereLibNotInArray: [
                    { LibValeur: this.globalSearch?.SelectedEtats?.length == 0 ? 'Ouvert' : null, LibKey: 'Etat' },
                ],
                ticketSortBy: this.ticketSortBy
            });
            this.tickets_mongoDB = results.data;
            this.pagination.data = results.pagination;
        },

        VoirDetailsTicket(ticket_id) {
            this.$router.push({ name: "detailsticket", params: { id: ticket_id } }); //
        },

        callFunctions(from, to, type) {
            if (this.currentSite?._id) {
                let range = from
                this.fetchTickets(range, to);
            }
        }
    },
    computed: {
        ...mapGetters({ isRole: "isRole", isAuthenticated: "isAuthenticated", res: "StateRes", currentSite: "currentSite" }),
        ...mapState(["ticket_id", "datePicker", "globalSearch"]),
        ticketsMongoDB() {
            return this.tickets_mongoDB;
        },
    },
};
</script>