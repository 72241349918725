<!-- eslint-disable -->
<script setup>
import weatherComponent from "@/components/weather/index.vue";
</script>
<!-- eslint-disable -->
<template>
  <div class="row mb-3">
    <div class="col-xl-6 col-lg-6 mb-3 d-flex align-items-stretch card-100">
      <div class="card card-stats mb-4 mb-xl-0">
        <div class="card-body" v-if="dashboard?.stat_principal?.data">
          <div
            class="statistique statistique-font-size font-weight-bold mb-0 d-flex height-100 sjustify-content-center align-items-center">
            <div class="w-100">
              <div class="text-centers text-uppercase font-size-20">
                <div>
                  <span class="percentage">
                    <i v-if="dashboard?.stat_principal?.percentage?.percentageCouvert?.arrow == 'down'"
                      class="fa fa-arrow-down red" aria-hidden="true"></i>
                    <i v-if="dashboard?.stat_principal?.percentage?.percentageCouvert?.arrow == 'up'"
                      class="fa fa-arrow-up green" aria-hidden="true"></i>
                    <span :class="{ 'red': dashboard?.stat_principal?.percentage?.percentageCouvert?.arrow == 'down' }">
                      {{
                        dashboard?.stat_principal?.percentage?.percentageCouvert?.value }} % </span>
                  </span>
                  {{ $t("content.pages.dashboard.cards.couvert_n.couvert") }} :
                  <span class="float-end">
                    {{
                      dashboard?.stat_principal?.data[0]?.entete
                        ? dashboard?.stat_principal?.data[0]?.entete?.totalCouvertTable
                        : "0"
                    }}
                  </span>
                </div>
              </div>
              <div class="text-centers text-uppercase font-size-20 mt-2">
                <div>
                  <span class="percentage">
                    <i v-if="dashboard?.stat_principal?.percentage?.percentageCouvertMoyenneTable?.arrow == 'down'"
                      class="fa fa-arrow-down red" aria-hidden="true"></i>
                    <i v-if="dashboard?.stat_principal?.percentage?.percentageCouvertMoyenneTable?.arrow == 'up'"
                      class="fa fa-arrow-up green" aria-hidden="true"></i>
                    <span
                      :class="{ 'red': dashboard?.stat_principal?.percentage?.percentageCouvertMoyenneTable?.arrow == 'down' }">
                      {{
                        dashboard?.stat_principal?.percentage?.percentageCouvertMoyenneTable?.value }} % </span>
                  </span>
                  {{ $t("content.pages.dashboard.cards.couvert_n.couvert_moyen") }}:
                  <span class="float-end">
                    {{
                      dashboard?.stat_principal?.data[0]?.entete
                        ? dashboard?.stat_principal?.data[0]?.entete?.totalCouvertMoyenneTable?.toFixed(2)
                        : "0.00"
                    }}
                    €
                  </span>
                </div>
              </div>
              <div class="text-centers text-uppercase font-size-20 mt-2">
                <div>
                  <span class="percentage">
                    <i v-if="dashboard?.stat_principal?.percentage?.percentageChiffreCATable?.arrow == 'down'"
                      class="fa fa-arrow-down red" aria-hidden="true"></i>
                    <i v-if="dashboard?.stat_principal?.percentage?.percentageChiffreCATable?.arrow == 'up'"
                      class="fa fa-arrow-up green" aria-hidden="true"></i>
                    <span
                      :class="{ 'red': dashboard?.stat_principal?.percentage?.percentageChiffreCATable?.arrow == 'down' }">
                      {{
                        dashboard?.stat_principal?.percentage?.percentageChiffreCATable?.value }} % </span>
                  </span>
                  {{ $t("content.pages.dashboard.cards.couvert_n.ca_table") }} :
                  <span class="float-end">
                    {{
                      dashboard?.stat_principal?.data[0]?.entete
                        ? dashboard?.stat_principal?.data[0]?.entete?.totalChiffreCATable.toFixed(
                          2
                        )
                        : "0.00"
                    }}
                    €
                  </span>
                </div>
              </div>
              <div class="text-centers text-uppercase font-size-20 mt-2">
                <div>
                  <span class="percentage">
                    <i v-if="dashboard?.stat_principal?.percentage?.percentageChiffreCA?.arrow == 'down'"
                      class="fa fa-arrow-down red" aria-hidden="true"></i>
                    <i v-if="dashboard?.stat_principal?.percentage?.percentageChiffreCA?.arrow == 'up'"
                      class="fa fa-arrow-up green" aria-hidden="true"></i>
                    <span
                      :class="{ 'red': dashboard?.stat_principal?.percentage?.percentageChiffreCA?.arrow == 'down' }">
                      {{
                        dashboard?.stat_principal?.percentage?.percentageChiffreCA?.value }} % </span>
                  </span>
                  {{ $t("content.pages.dashboard.cards.couvert_n.ca") }} :
                  <span class="float-end">
                    {{
                      dashboard?.stat_principal?.data[0]?.entete
                        ? dashboard?.stat_principal?.data[0]?.entete?.totalChiffreCA.toFixed(
                          2
                        )
                        : "0.00"
                    }}
                    €
                  </span>
                </div>
              </div>
              <div class="text-centers text-uppercase font-size-20 mt-2">
                <div>
                  <span class="percentage">
                    <i v-if="dashboard?.stat_principal?.percentage?.percentageValeur?.arrow == 'down'"
                      class="fa fa-arrow-down red" aria-hidden="true"></i>
                    <i v-if="dashboard?.stat_principal?.percentage?.percentageValeur?.arrow == 'up'"
                      class="fa fa-arrow-up green" aria-hidden="true"></i>
                    <span :class="{ 'red': dashboard?.stat_principal?.percentage?.percentageValeur?.arrow == 'down' }">
                      {{
                        dashboard?.stat_principal?.percentage?.percentageValeur?.value }} % </span>
                  </span>
                  {{ $t("content.pages.dashboard.cards.couvert_n.reglements") }} :
                  <span class="float-end">
                    {{
                      dashboard?.stat_principal?.data[0]?.reglements
                        ? dashboard?.stat_principal?.data[0]?.reglements?.TotalValeur.toFixed(2)
                        : "0.00"
                    }}
                    €
                  </span>
                </div>
              </div>
              <div class="text-center text-uppercase hr-border">
                <h3 v-if="datePicker?.defaultDates?.type != null">
                  {{ $t(`content.button.${datePicker?.defaultDates?.type}`) }}
                </h3>
                <h3 v-else>
                  {{ 'Personnalisé' }}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-6 mb-3 d-flex align-items-stretch card-100">
      <div class="card card-stats mb-4 mb-xl-0">
        <div class="card-body" v-if="dashboard?.stat_principal?.data">
          <div
            class="statistique statistique-font-size font-weight-bold mb-0 d-flex height-100 sjustify-content-center align-items-center">
            <div class="w-100">
              <div class="text-centers text-uppercase font-size-20">
                <div>
                  {{ $t("content.pages.dashboard.cards.couvert_n_1.couvert") }} :<span class="float-end">
                    {{
                      dashboard?.stat_principal?.data[1]?.entete
                        ? dashboard?.stat_principal?.data[1]?.entete?.totalCouvertTable
                        : "0"
                    }}
                  </span>
                </div>
              </div>
              <div class="text-centers text-uppercase font-size-20 mt-2">
                <div>
                  {{ $t("content.pages.dashboard.cards.couvert_n_1.couvert_moyen") }} :
                  <span class="float-end">
                    {{
                      dashboard?.stat_principal?.data[1]?.entete
                        ? dashboard?.stat_principal?.data[1]?.entete?.totalCouvertMoyenneTable?.toFixed(2)
                        : "0.00"
                    }}
                    €
                  </span>
                </div>
              </div>
              <div class="text-centers text-uppercase font-size-20 mt-2">
                <div>
                  {{ $t("content.pages.dashboard.cards.couvert_n_1.ca_table") }} :
                  <span class="float-end">
                    {{
                      dashboard?.stat_principal?.data[1]?.entete
                        ? dashboard?.stat_principal?.data[1]?.entete?.totalChiffreCATable.toFixed(
                          2
                        )
                        : "0.00"
                    }}
                    €
                  </span>
                </div>
              </div>
              <div class="text-centers text-uppercase font-size-20 mt-2">
                <div>
                  {{ $t("content.pages.dashboard.cards.couvert_n_1.ca") }} :
                  <span class="float-end">
                    {{
                      dashboard?.stat_principal?.data[1]?.entete
                        ? dashboard?.stat_principal?.data[1]?.entete?.totalChiffreCA.toFixed(
                          2
                        )
                        : "0.00"
                    }}
                    €
                  </span>
                </div>
              </div>
              <div class="text-centers text-uppercase font-size-20 mt-2">
                <div>
                  <span>{{
                    $t("content.pages.dashboard.cards.couvert_n_1.reglements")
                  }}</span>
                  :
                  <span class="float-end">
                    {{
                      dashboard?.stat_principal?.data[1]?.reglements
                        ? dashboard?.stat_principal?.data[1]?.reglements?.TotalValeur?.toFixed(2)
                        : "0.00"
                    }}
                    €
                  </span>
                </div>
              </div>
              <div class="text-center text-uppercase hr-border">

                <h3>{{ $t("content.pages.dashboard.cards.couvert_n_1.type") }}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-6 mb-3 d-flex align-items-stretch card-100">
      <div class="card card-stats mb-4 mb-xl-0">
        <div class="card-body">
          <div class="row height-100">
            <div class="col height-100">
              <div
                class="statistique statistique-font-size font-weight-bold mb-0 d-flex height-100 justify-content-center align-items-center">
                <div class="text-center">

                  <div class="mt-2">
                    <span>
                      {{
                        dashboard.meilleur_jour
                          ? dashboard?.meilleur_jour?.TotalValue?.toFixed(2)
                          : "0.00"
                      }}
                      €
                    </span>
                  </div>
                  <div>
                    <p class="mt-1 mb-0 text-muteds text-sm">
                      <span class="text-nowrap font-size-20">
                        {{
                          dashboard.meilleur_jour
                            ? $moment(dashboard?.meilleur_jour?.jour).format(
                              "DD-MM-YYYY"
                            )
                            : "DD-MM-YYYY"
                        }}
                      </span>
                    </p>
                  </div>
                  <div class="hr-border">
                    <span style="font-size: 15px"> {{ $t("content.pages.dashboard.cards.best_day.title") }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-lg-6 mb-3  d-flex align-items-stretch card-100">
      <weatherComponent
        :EmetteurVille="currentSite?.sites?.length == 1 ? currentSite?.sites[0]?.EmetteurVille : 'Paris'" />
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 grid-margin stretch-card">
      <div class="card">
        <div class="card-header d-flex align-items-center justify-content-between">
          <h3 class="card-title float-start">CA</h3>
        </div>
        <div v-if="chartjs.chartData" class="card-body">
          <div class="mb-2 text-center">
            <span>
              Date du <b>{{ datePicker.date.from }} </b> au
              <b> {{ datePicker.date.to }}</b>
            </span>
          </div>
          <div class="row mb-2">
            <div class="col-md-6">
              <span class="statistique statistique-font-size font-weight-bold mb-0">
                {{ chiffre_affaire_total.toFixed(2) }} €
              </span>
            </div>
            <div class="col-md-12">
              <div class="type_ca float-end">
                <span class="brut" :class="{ active: type_ca == 'MontantHT' }" @click="ChangeCAType('brut')">
                  Montant HT
                </span>
                <span class="net" :class="{ active: type_ca == 'MontantTTC' }" @click="ChangeCAType('net')">Montant
                  TTC</span>
              </div>
            </div>
          </div>
          <LineChart :chartData="chartjs.chartData" />
        </div>
      </div>
    </div>

    <!-- <div class="col-md-4 grid-margin stretch-card">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title text-left">TOTAL Chiffre d'affaires par heure</h3>
          <span>
            <b>Heure de début : </b>{{ TimeDebutCalclule?.TimeDebut }}
            <b>Heure de fin : </b> {{ TimeDebutCalclule?.TimeFin }}
          </span>
        </div>
        <div v-if="dashboard.ca_per_hour && dashboard.ca_per_hour.length > 0" class="card-body">
          <div class="table-responsive tableClasss">

            <DataTable showGridlines stripedRows :value="dashboard.ca_per_hour">
              <Column field="heur" header="Heure" sortable> </Column>
              <Column field="total" header="CA (€)" sortable>
                <template #body="slotProps">
                  {{ slotProps?.data?.total?.toFixed(2) }} €
                </template>
</Column>
</DataTable>
</div>
</div>
<div v-else class="card-body d-flex align-items-center justify-content-center">
  <datanotfound :text="$t(`content.message.datanotfound`)" />
</div>
</div>
</div>
<div class="col-md-4 grid-margin stretch-card">
  <div class="card">
    <div class="card-header">
      <h3 class="card-title text-left">TOTAL Règlement par heure</h3>
      <span>
        <b>Heure de début : </b>{{ TimeDebutCalclule?.TimeDebut }}
        <b>Heure de fin : </b> {{ TimeDebutCalclule?.TimeFin }}
      </span>
    </div>
    <div v-if="dashboard.payments_per_hour && dashboard.payments_per_hour.length > 0" class="card-body">
      <div class="table-responsive tableClasss">

        <DataTable showGridlines stripedRows :value="dashboard.payments_per_hour">
          <Column field="heur" header="Heure" sortable> </Column>
          <Column field="total" header="Règlement (€)" sortable>
            <template #body="slotProps">
                  {{ slotProps?.data?.total?.toFixed(2) }} €
                </template>
          </Column>
        </DataTable>
      </div>
    </div>
    <div v-else class="card-body d-flex align-items-center justify-content-center">
      <datanotfound :text="$t(`content.message.datanotfound`)" />
    </div>
  </div>
</div>-->
    <div class="col-md-6 grid-margin stretch-card">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title text-left">TOTAL CA et Règlements</h3>
          <span>
            <b>Heure de début : </b>{{ TimeDebutCalclule?.TimeDebut }}
            <b>Heure de fin : </b> {{ TimeDebutCalclule?.TimeFin }}
          </span>
        </div>
        <div v-if="results && results.length > 0" class="card-body">
          <div class="table-responsive tableClasss">

            <DataTable showGridlines stripedRows :value="results">
              <Column field="heur" header="Heure" sortable> </Column>
              <Column field="total" header="Total (€)" sortable>
                <template #body="slotProps">
                  {{ slotProps?.data?.total?.toFixed(2) }} €
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
        <div v-else class="card-body d-flex align-items-center justify-content-center">
          <datanotfound :text="$t(`content.message.datanotfound`)" />
        </div>
      </div>
    </div>
    <div class="col-md-6 grid-margin stretch-card">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title text-left">CA par caisse</h3>
        </div>
        <div v-if="dashboard.chiffre_affaires_par_caisse.length > 0" class="card-body">
          <div class="table-responsive tableClasss">
            <DataTable showGridlines stripedRows :value="dashboard.chiffre_affaires_par_caisse">
              <Column field="LibCaisse" header="Caisse" sortable></Column>
              <Column field="ca" header="Total" sortable>
                <template #body="slotProps">
                  {{ slotProps?.data?.ca?.toFixed(2) }} €
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
        <div v-else class="card-body d-flex align-items-center justify-content-center">
          <datanotfound :text="$t(`content.message.datanotfound`)" />
        </div>
      </div>
    </div>

    <div class="col-md-6 grid-margin stretch-card">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title text-left">Meilleures 10 ventes</h3>
        </div>
        <div v-if="dashboard.meilleurventes.data && dashboard.meilleurventes.data.length > 0" class="card-body">
          <div class="table-responsive tableClasss">
            <DataTable showGridlines stripedRows :value="dashboard.meilleurventes.data">
              <Column field="Libelle" header="Article" sortable> </Column>
              <Column field="QteTotal" header="Quantité" sortable> </Column>
              <Column field="TotalTTC" header="Montant (€)" sortable>
                <template #body="slotProps">
                  {{ slotProps?.data?.TotalTTC?.toFixed(2) }} €
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
        <div v-else class="card-body d-flex align-items-center justify-content-center">
          <datanotfound :text="$t(`content.message.datanotfound`)" />
        </div>
      </div>
    </div>

    <div class="col-md-6 grid-margin stretch-card">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title text-left">Reglements par mode de paiement</h3>
        </div>
        <div v-if="dashboard.list_reglements_groupby_famille &&
          dashboard.list_reglements_groupby_famille.length > 0
        " class="card-body">
          <div class="table-responsive tableClasss">

            <DataTable showGridlines stripedRows :value="dashboard.list_reglements_groupby_famille">
              <Column field="LibFamille" header="Mode de paiement" sortable> </Column>
              <Column field="LibCaisse" header="Caisse" sortable> </Column>
              <Column field="TotalValeur" header="règlements (€)" sortable>
                <template #body="slotProps">
                  {{ slotProps?.data?.TotalValeur?.toFixed(2) }} €
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
        <div v-else class="card-body d-flex align-items-center justify-content-center">
          <datanotfound :text="$t(`content.message.datanotfound`)" />
        </div>
      </div>
    </div>


    <div class="col-md-6 grid-margin stretch-card">
      <div class="card">
        <div class="card-header">
          <h3 class="card-title text-left">Statistiques des serveurs</h3>

        </div>
        <div v-if="dashboard.statistiques_serveurs && dashboard.statistiques_serveurs.length > 0" class="card-body">
          <div class="table-responsive tableClasss">

            <DataTable showGridlines stripedRows :value="dashboard.statistiques_serveurs">
              <Column field="_id" header="Serveur" sortable> </Column>
              <Column field="TotalCouverts" header="Nb couverts" sortable>
                <template #body="slotProps">
                  {{ slotProps?.data?.TotalCouverts }}
                </template>
              </Column>
              <Column field="totalMontantTTC" header="CA (€)" sortable>
                <template #body="slotProps">
                  {{ slotProps?.data?.totalMontantTTC?.toFixed(2) }} €
                </template>
              </Column>
              <Column field="totalValeur" header="Encaisements (€)" sortable>
                <template #body="slotProps">
                  {{ slotProps?.data?.totalValeur?.toFixed(2) }} €
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
        <div v-else class="card-body d-flex align-items-center justify-content-center">
          <datanotfound :text="$t(`content.message.datanotfound`)" />
        </div>
      </div>
    </div>
  </div>
</template>
<!-- eslint-disable -->
<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import LineChart from "@/components/chartVue/ChartLine.vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
export default {
  components: { LineChart, VueDatePicker },
  name: "mongoDATA",
  watch: {
    "datePicker.date.range"(newDate, oldDate) {
      this.callFunctions(
        this.datePicker.date.from,
        this.datePicker.date.to,
        this.datePicker.defaultDates.type
      );
    },
  },
  data() {
    return {
      selectedDates: null,
      userLocation: null,
      weatherData: null,
      apiKey: "d5d29bfed8ee0aa4b167f3bbd42204ce",
      city: null,
      statistiques_mongoDB: [],
      meilleurventes_mongoDB: [],
      reglements_mongoDB: [],
      stat_principal_mongoDB: [],
      encaissement_mongoDB: [],
      dashboard: {
        meilleurventes: [],
        meilleur_jour: [],
        ca_per_hour: [],
        payments_per_hour: [],
        statistiques_serveurs: [],
        revenu_statistques: [],
        stat_principal: [],
        chiffre_affaires_total: [],
        chiffre_affaires_par_caisse: [],
        total_reglements: [],
        total_reglements_n_1: [],
        list_reglements_groupby_famille: [],
      },
      chartjs: {
        ca_chartjs: {},
        couverts_chartjs: {},
        chartData: [],
        FilterBy: "hour",
      },
      chiffre_affaire_total: 0,
      type_ca: "MontantTTC",
      namedate: "fiveyear",
      per_page: 10,
      pagination: {
        page: 0,
        limit: 4,
        skip: 0,
        per_page: 10,
        data: [],
      },
      interval: null,
    };
  },
  mounted() {
    if (this.isAuthenticated && this.isRole == 'manager') {
      this.getMeilleurJourDuMois('month', 'month');
    }
  },
  created() { },
  unmounted() {
  },
  methods: {
    ...mapMutations(["setTicketID"]),
    ...mapActions([
      "getReglements",
      "BoardMeilleursVents",
      "BoardRevenuStatistiques",
      "BoardChiffreAffaires",
      "TotalReglements",
      "BoardStatPrincipal",
      "BoardMeilleurJourDuMois",
      "BoardCaPerHour",
      "BoardPaymentsPerHour",
      "BoardStatistiquesServeurs",
      "BoardChiffreAffairesChartJs",
      "BoardCouvertsChartJs",
    ]),

    async getReglementsGroupByFamille(from, to) {
      let qte = "qte";
      let vendeur = "vendeur";
      let self = this;
      this.dashboard.list_reglements_groupby_famille = await this.getReglements({
        url: "entetes/reglements",
        range: self.datePicker.date.range,
        vendeur: vendeur,
        site: self.currentSite?._id,
        qte: qte,
      });
    },

    callFunctions(from, to, type) {
      if (this.currentSite?._id) {
        let range = from
        this.getReglementsGroupByFamille(range, to); // par mode de Piaements
        this.getBoardMeilleursVents(range, to); // 
        this.getRevenuStatistiques(range, to);
        this.getChiffreAffairesParCaisse(range, to);
        this.getStatPrincipal(range, to);
        this.getCaPerHour(range, to);
        this.getPaymentsPerHour(range, to);
        this.getStatistiquesServeurs(range, to);
        this.getChiffreAffairesChartJs(range, to, this.chartjs.FilterBy);
      }
    },

    /** DASHBOARD    */
    async getBoardMeilleursVents(from, to) {
      let qte = "qte";
      let page = 1;
      let limit = 10;
      let self = this;
      this.dashboard.meilleurventes = await this.BoardMeilleursVents({
        url: "board/meilleursvents",
        page: page,
        limit: limit,
        range: self.datePicker.date.range,

        vendeur: "vendeur",
        site: self.currentSite?._id,
        qte: qte,
        WhereLibInArray: [
          { LibValeur: 'vendeur', LibKey: 'LibVendeur' },
          { LibValeur: 'caisse', LibKey: 'LibCaisse' },
          { LibValeur: ['VENTE', 'ANNULATION'], LibKey: 'Operation' },
        ]
      });
    },
    async getRevenuStatistiques(from, to) {
      let qte = "qte";
      let page = 1;
      let limit = 0;
      let self = this;
      this.dashboard.revenu_statistques = await this.BoardRevenuStatistiques({
        url: "board/revenu-statistiques",
        page: page,
        limit: limit,
        range: self.datePicker.date.range,

        vendeur: "vendeur",
        site: self.currentSite?._id,
        qte: qte,
        groupbycaisse: "none",
        WhereLibInArray: [
          { LibValeur: 'vendeur', LibKey: 'LibVendeur' },
          { LibValeur: 'caisse', LibKey: 'LibCaisse' },
          { LibValeur: null, LibKey: 'Operation' },
        ]
      });
    },
    async getChiffreAffairesParCaisse(from, to) {
      let qte = "qte";
      let page = 1;
      let limit = 0;
      let groupbycaisse = "oui";
      let self = this;
      this.dashboard.chiffre_affaires_par_caisse = await this.BoardChiffreAffaires({
        url: "board/chiffre-affaires",
        page: page,
        limit: limit,
        range: self.datePicker.date.range,

        vendeur: "vendeur",
        site: self.currentSite?._id,
        qte: qte,
        groupbycaisse: groupbycaisse,
        WhereLibInArray: [
          { LibValeur: 'vendeur', LibKey: 'LibVendeur' },
          { LibValeur: 'caisse', LibKey: 'LibCaisse' },
          { LibValeur: ['VENTE', 'TRANSFERT', 'ANNULATION'], LibKey: 'Operation' },
        ]
      });
    },

    async getStatPrincipal(from, to) {
      this.dashboard.stat_principal = []
      let qte = "qte";
      let page = 1;
      let limit = 0;
      let self = this;
      this.dashboard.stat_principal = await this.BoardStatPrincipal({
        url: "board/stat-principal",
        page: page,
        limit: limit,
        range: self.datePicker.date.range,
        range_n: self.datePicker.date_n.range,
        vendeur: "vendeur",
        site: self.currentSite?._id,
        qte: qte,
        WhereLibInArray: [
          { LibValeur: 'vendeur', LibKey: 'LibVendeur' },
          { LibValeur: 'caisse', LibKey: 'LibCaisse' },
          { LibValeur: ['VENTE', 'TRANSFERT', 'ANNULATION'], LibKey: 'Operation' },
        ]
      });
    },
    async getMeilleurJourDuMois(from, to) {
      if (this.currentSite?._id) {
        let qte = "qte";
        let page = 1;
        let limit = 0; // select first element
        let self = this;
        this.dashboard.meilleur_jour = await this.BoardMeilleurJourDuMois({
          url: "board/meilleur-jour-du-mois",
          page: page,
          limit: limit,
          range: self?.datePicker?.defaultDates?.list[4] || null,
          vendeur: "vendeur",
          site: self.currentSite?._id,
          qte: qte,
          WhereLibInArray: [
            { LibValeur: 'vendeur', LibKey: 'LibVendeur' },
            { LibValeur: 'caisse', LibKey: 'LibCaisse' },
            { LibValeur: null, LibKey: 'Operation' },
          ]
        });
      }
    },

    async getCaPerHour(from, to) {
      let qte = "qte";
      let page = 1;
      let limit = 1; // select first element
      let self = this;
      this.dashboard.ca_per_hour = await this.BoardCaPerHour({
        url: "board/ca-per-hour",
        page: page,
        limit: limit,
        range: self.datePicker.date.range,

        vendeur: "vendeur",
        site: self.currentSite?._id,
        qte: qte,
        WhereLibInArray: [
          { LibValeur: 'vendeur', LibKey: 'LibVendeur' },
          { LibValeur: 'caisse', LibKey: 'LibCaisse' },
          { LibValeur: ['VENTE', 'TRANSFERT', 'ANNULATION'], LibKey: 'Operation' },
        ]
      });
    },
    async getPaymentsPerHour(from, to) {
      console.log('hello paiment per horu')
      let qte = "qte";
      let page = 1;
      let limit = 1; // select first element
      let self = this;
      this.dashboard.payments_per_hour = await this.BoardPaymentsPerHour({
        url: "board/payment-per-hour",
        page: page,
        limit: limit,
        range: self.datePicker.date.range,
        vendeur: "vendeur",
        site: self.currentSite?._id,
        qte: qte,
        WhereLibInArray: [
          { LibValeur: 'vendeur', LibKey: 'LibVendeur' },
          { LibValeur: 'caisse', LibKey: 'LibCaisse' },
          { LibValeur: ['VENTE', 'TRANSFERT', 'ANNULATION'], LibKey: 'Operation' },
        ]
      });
    },
    async getStatistiquesServeurs(from, to) {
      let qte = "qte";
      let page = 1;
      let limit = 1;
      let self = this;
      this.dashboard.statistiques_serveurs = await this.BoardStatistiquesServeurs({
        url: "board/serveurs",
        page: page,
        limit: limit,
        range: self.datePicker.date.range,
        vendeur: "vendeur",
        site: self.currentSite?._id,
        qte: qte,
        WhereLibInArray: [
          { LibValeur: 'vendeur', LibKey: 'LibVendeur' },
          { LibValeur: 'caisse', LibKey: 'LibCaisse' },
          { LibValeur: ['VENTE', 'TRANSFERT', 'ANNULATION'], LibKey: 'Operation' },
        ]
      });
    },

    async getChiffreAffairesChartJs(from, to, groupby_date) {
      let groupby_dates = ''
      if (this.datePicker.defaultDates.type == 'today') {
        groupby_dates = 'hour'
      } else if (this.datePicker.defaultDates.type == 'yesterday') {
        groupby_dates = 'hour'
      } else if (this.datePicker.defaultDates.type == 'week') {
        groupby_dates = 'day'
      } else if (this.datePicker.defaultDates.type == 'month') {
        groupby_dates = 'day'
      } else if (this.datePicker.defaultDates.type == 'year') {
        groupby_dates = 'month'
      }
      let qte = "qte";
      let page = 1;
      let limit = 0; // select first element
      let self = this;
      this.chartjs.ca_chartjs = await this.BoardChiffreAffairesChartJs({
        url: "board/ca-chartjs",
        page: page,
        limit: limit,
        range: self.datePicker.date.range,

        vendeur: "vendeur",
        site: self.currentSite?._id,
        qte: qte,
        type_ca: self.type_ca,
        groupby_date: groupby_dates,
        WhereLibInArray: [
          { LibValeur: 'vendeur', LibKey: 'LibVendeur' },
          { LibValeur: 'caisse', LibKey: 'LibCaisse' },
          { LibValeur: ['VENTE', 'TRANSFERT', 'ANNULATION'], LibKey: 'Operation' },
        ]
      });
      let labels = [];
      let datasets = [];
      let datasetsCouverts = [];

      self.chiffre_affaire_total = 0;
      self.nb_couverts_total = 0;
      const dayNames = [
        "Dimanche",
        "Lundi",
        "Mardi",
        "Mercredi",
        "Jeudi",
        "Vendredi",
        "Samedi",
      ];
      //return dayNames[dayOfWeek];
      await this.chartjs.ca_chartjs.map((el) => {
        if (groupby_date == "day") {
          labels.push(dayNames[el.name_month - 1]);
        } else {
          labels.push(el.labels);
        }

        datasets.push(el?.chiffre_affaire?.toFixed(2));
        self.chiffre_affaire_total += el.chiffre_affaire;

      });
      this.chartjs.chartData = {};
      let ca_label = this.type_ca == "MontantHT" ? "CA HT" : "CA TTC";
      this.chartjs.chartData = {
        labels: labels,
        datasets: [
          {
            label: ca_label,
            borderColor: "yellow",
            data: datasets,
            fill: true,
            tension: 0.4,
            backgroundColor: '#abd8f5',
          },
        ],
      };

    },
    ChangeCAType(type) {
      this.type_ca = type == "brut" ? "MontantHT" : "MontantTTC";
      this.getChiffreAffairesChartJs(
        this.datePicker.date.from,
        this.datePicker.date.to,
        this.chartjs.FilterBy //  
      );
    },
    ChartJsfilterBy() {
      this.getChiffreAffairesChartJs(
        this.datePicker.date.from,
        this.datePicker.date.to,
        this.chartjs.FilterBy //   
      );
    },



  },
  computed: {
    ...mapGetters({
      isAuthenticated: "isAuthenticated",
      isRole: "isRole",
      res: "StateRes",
      currentSite: "currentSite",
      listChiffreAffaires: "listChiffreAffaires",
      TimeDebutCalclule: "TimeDebutCalclule"
    }),
    ...mapState(["ticket_id", "datePicker"]),
    results() {
      // Create a map to store totals by heur
      let resultMap = new Map();

      // Function to update resultMap with totals from array
      const updateMap = (array) => {
        array.forEach(item => {
          if (resultMap.has(item.heur)) {
            resultMap.set(item.heur, resultMap.get(item.heur) + item.total);
          } else {
            resultMap.set(item.heur, item.total);
          }
        });
      };

      // Update resultMap with totals from both arrays
      updateMap(this.dashboard.ca_per_hour);
      updateMap(this.dashboard.payments_per_hour);

      // Convert resultMap to array format
      return Array.from(resultMap, ([heur, total]) => ({ heur, total }));
    }

  },
};
</script>
<!-- eslint-disable -->

<style>
.selected-default-dates {
  background: #084593 !important;
  color: #fff !important;
}

.card-100 .card {
  width: 100%;
}

.card .card-title {
  margin-bottom: 0px !important;
}



.type_ca span.brut {
  border-left: 1px solid #d9d9d9;
  border-right: 0px solid #d9d9d9;
  border-radius: 2px 0 0 2px;
}

.type_ca span.net {
  border-left: 0px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
  border-radius: 0 2px 2px 0;
}

.btn-event-export {
  border: 1px solid #bdbdbd;
  padding: 5px;
  text-align: center;
  background: #fff;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.statistique-font-size {
  font-size: 20px;
}

.statistique {
  font-weight: 600;
  margin-top: 10px !important;
  display: block;
  margin: 0px !important;
}

.height-100 {
  height: 100%;
}

.geolocation.latlong {
  font-size: 15px;
}

.font-size-20 {
  font-size: 14px;
}

.card-body {
  padding: 0.8rem 0.8rem !important;
}


.percentage svg {
  margin-right: 4px;
}

.percentage .red {
  color: red !important;
}

.percentage .green,
.percentage {
  color: #14e114;
}

.percentage {
  width: 90px;
  display: inline-block;
}
</style>
