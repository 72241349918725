<!-- eslint-disable -->
<script setup>
import ButtonDates from "@/components/ButtonDates.vue";
</script>
<!-- eslint-disable -->
<template>
  <div v-if="$route">
    <div v-if="loader" class="loader"></div>
    <div v-if="$route.name === 'login'">
      <LoginView />
    </div>
    <div v-else-if="$route.name === 'register'">
      <RegisterView />
    </div>
    <div v-else-if="$route.name === 'creation-restaurant'">
      <AbonnerRestaurant />
    </div>
    <div v-else-if="$route.name === 'payement-success'">
      <payementSuccess />
    </div>
    <div v-else-if="$route.name === 'payement-cancled'">
      <payementCancled />
    </div>
    <div v-else>
      <div class="main-wrapper">
        <SideBar v-if="$route?.meta?.withComponents?.includes('sidebar')" />
        <div class="page-wrapper">
          <NavBar v-if="$route?.meta?.withComponents?.includes('navbar')" />
          <div class="page-content">
            <WelcomeToReporting
              v-if="$route?.meta?.withComponents?.includes('WelcomeToReporting') && ($route.name == 'creation-restaurant') || ($store.state.managerWithStores?.associatedRestaurants.length <= 0)" />
            <AbonnementExpirerView
              v-else-if="$route?.meta?.withComponents?.includes('AbonnementExpirerView') && $route.name != 'renouveler' && $route.name != 'creation-restaurant' && $route.name != 'list-restaurants' && $route.name != 'details-restaurant-manager' && $route.name != 'modifier-restaurant-manager' && !StateIsAbonnementActif && $store.state.managerWithStores?.associatedRestaurants.length" />
            <div v-else>
              <ButtonDates v-if="$route?.meta?.isComponentDates && !loader" />
              <router-view />
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>

  </div>
</template>
<!-- eslint-disable -->
<script>
import LoginView from "@/views/LoginView.vue";
import RegisterView from "@/views/RegisterView.vue";
import AbonnerRestaurant from "@/views/Abonner/AbonnerRestaurant.vue";
import payementSuccess from "@/views/Abonner/payementSuccess.vue";
import payementCancled from "@/views/Abonner/payementCancled.vue";
import AbonnementExpirerView from "@/views/AbonnementExpirerView.vue";

import NavBar from "@/components/NavBar.vue";
import SideBar from "@/components/SideBar.vue";
import Footer from "@/components/Footer.vue";
import { mapGetters, mapActions, mapState } from "vuex";
import WelcomeToReporting from "./views/WelcomeToReporting.vue";

export default {
  components: {
    LoginView,
    RegisterView,
    NavBar,
    SideBar,
    Footer,
    WelcomeToReporting,
    AbonnerRestaurant,
    payementSuccess,
    payementCancled,
    AbonnementExpirerView
  },
  /* eslint-disable */
  mounted() {
    const plugin = document.createElement("script");

    plugin.setAttribute(
      "src",
      "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/js/all.min.js"
    );
    plugin.async = true;
    document.head.appendChild(plugin);

    const link = document.createElement("link");
    link.setAttribute(
      "href",
      "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css"
    );
    link.async = true;
    document.head.appendChild(link);



  },
  beforeUpdate() {

  },
  methods: {
  },
  computed: {
    ...mapGetters({ ManagerWithStore: "StateManagerWithStore", StateUser: "StateUser", isRole: "isRole", isAuthenticated: "isAuthenticated", BaseUrl: "StateBase", currentSite: "currentSite", parametrages: "StateParametrages", StateIsAbonnementActif: "StateIsAbonnementActif" }),
    ...mapState({ loader: 'formLoader' })
  }

};
</script>
<!-- eslint-disable -->
<style lang="scss">
@import "../public/assets/css/style.css";



.loader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #e1e0e091;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}

.p-datatable .p-datatable-tbody>tr>td {
  font-size: 13px !important;
  font-weight: 600 !important;
}

.table-tag {
  padding: 6px;
  display: block;
  border-radius: 8px;
  width: auto;
  text-align: center;
  font-weight: 700;
}

.p-datatable .p-datatable-thead>tr>th {
  font-weight: 700 !important;
  font-size: 13px !important;
  color: #fff !important;
  background: #040810 !important;
}

.p-datatable .p-sortable-column:not(.p-highlight):hover {
  background: #070c15bd !important;
  color: #fff !important;
}


.export-btn .col-btn {
  padding: 5px 7px;
  cursor: pointer;
  font-weight: 500;
  margin: 3px;
  position: relative;

}

.grid-3 {
  grid-template-columns: repeat(3, 1fr) !important;
  display: grid;
}

.grid-2 {
  grid-template-columns: repeat(2, 1fr) !important;
  display: grid;
}

/**
Responsive */

@media only screen and (min-width:991px) {
  .grid-3 {
    grid-template-columns: repeat(3, 1fr) !important;
  }

}

@media only screen and (max-width:990px) and (min-width:601px) {
  .grid-3 {
    grid-template-columns: repeat(3, 1fr) !important;
  }

}

@media only screen and (max-width:600px) {

  .grid-3 svg {
    display: block !important;
    text-align: center !important;
    margin: 0 auto;
  }

  .grid-2 {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}
</style>
