<!-- eslint-disable -->
<template>
    <div class="card mb-3">
        <div class="card-body">
            <div v-if="listChiffreAffaires?.length > 0" class="row">
                <div class="col-md-12 type_ca grid-8 mb-3">
                    <span type="button" class="btn btn-info btn-icon-text  " @click="filterData('today', true)"
                        :class="{ 'selected-default-dates': datePicker.defaultDates.type == 'today' }">

                        {{ $t(`content.button.today`) }}
                        <p class="chiffre-afaire">
                            {{
                                listChiffreAffaires[0][0]
                                    ? listChiffreAffaires[0][0]["Chiffre d'affaires (CA)"].toFixed(2)
                                    : "0.00"
                            }}
                            €</p>
                    </span>

                    <span type="button" class="btn btn-info btn-icon-text  " @click="filterData('yesterday', true)"
                        :class="{ 'selected-default-dates': datePicker.defaultDates.type == 'yesterday' }">

                        {{ $t(`content.button.yesterday`) }}
                        <p class="chiffre-afaire">
                            {{
                                listChiffreAffaires[1][0]
                                    ? listChiffreAffaires[1][0]["Chiffre d'affaires (CA)"].toFixed(2)
                                    : "0.00"
                            }}
                            €</p>
                    </span>

                    <span type="button" class="btn btn-info btn-icon-text  " @click="filterData('week', true)"
                        :class="{ 'selected-default-dates': datePicker.defaultDates.type == 'week' }">

                        {{ $t(`content.button.week`) }}
                        <p class="chiffre-afaire">
                            {{
                                listChiffreAffaires[2][0]
                                    ? listChiffreAffaires[2][0]["Chiffre d'affaires (CA)"].toFixed(2)
                                    : "0.00"
                            }}
                            €</p>
                    </span>

                    <span type="button" class="btn btn-info btn-icon-text  " @click="filterData('previous_week', true)"
                        :class="{ 'selected-default-dates': datePicker.defaultDates.type == 'previous_week' }">

                        {{ $t(`content.button.previous_week`) }}
                        <p class="chiffre-afaire">
                            {{
                                listChiffreAffaires[3][0]
                                    ? listChiffreAffaires[3][0]["Chiffre d'affaires (CA)"].toFixed(2)
                                    : "0.00"
                            }} €</p>
                    </span>

                    <span type="button" class="btn btn-info btn-icon-text  " @click="filterData('month', true)"
                        :class="{ 'selected-default-dates': datePicker.defaultDates.type == 'month' }">

                        {{ $t(`content.button.month`) }}
                        <p class="chiffre-afaire">
                            {{
                                listChiffreAffaires[4][0]
                                    ? listChiffreAffaires[4][0]["Chiffre d'affaires (CA)"].toFixed(2)
                                    : "0.00"
                            }}
                            €</p>
                    </span>
                    <span type="button" class="btn btn-info btn-icon-text  " @click="filterData('previous_month', true)"
                        :class="{ 'selected-default-dates': datePicker.defaultDates.type == 'previous_month' }">

                        {{ $t(`content.button.previous_month`) }}
                        <p class="chiffre-afaire"> {{
                            listChiffreAffaires[5][0]
                                ? listChiffreAffaires[5][0]["Chiffre d'affaires (CA)"].toFixed(2)
                                : "0.00"
                        }} €</p>
                    </span>
                    <span type="button" class="btn btn-info btn-icon-text  " @click="filterData('year', true)"
                        :class="{ 'selected-default-dates': datePicker.defaultDates.type == 'year' }">

                        {{ $t(`content.button.year`) }}
                        <p class="chiffre-afaire">
                            {{
                                listChiffreAffaires[6][0]
                                    ? listChiffreAffaires[6][0]["Chiffre d'affaires (CA)"].toFixed(2)
                                    : "0.00"
                            }} €</p>
                    </span>
                    <span type="button" class="btn btn-info btn-icon-text  " @click="filterData('previous_year', true)"
                        :class="{ 'selected-default-dates': datePicker.defaultDates.type == 'previous_year' }">

                        {{ $t(`content.button.previous_year`) }}
                        <p class="chiffre-afaire">
                            {{
                                listChiffreAffaires[7][0]
                                    ? listChiffreAffaires[7][0]["Chiffre d'affaires (CA)"].toFixed(2)
                                    : "0.00"
                            }} €</p>
                    </span>

                </div>
                <div class="col-md-12 mb-3 d-flex">
                    <div class="w-50 p-1">
                        <label for="Date" class="form-label">Dates</label>
                        <VueDatePicker locale="fr" @update:model-value="handleDate" v-model="selectedDates"
                            :action-row="{ showSelect: false, showCancel: false }" range multiCalendars
                            :enableTimePicker="true" format="dd-MM-yyyy HH:mm" close-on-scroll auto-apply
                            show-now-button />
                    </div>
                    <div v-if="$route.name === 'dashboard'" class="w-50 p-1">
                        <label for="date-1" class="form-label">Date N-1</label>
                        <VueDatePicker locale="fr" v-model="selectedDatesN1" disabled
                            :action-row="{ showSelect: false, showCancel: false }" range multiCalendars
                            :enableTimePicker="true" format="dd-MM-yyyy HH:mm" close-on-scroll auto-apply
                            show-now-button />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<!-- eslint-disable -->
<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { DateTime } from "luxon";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
export default {
    components: { VueDatePicker },
    data() {
        return {
            selectedDates: [],
            selectedDatesN1: [],
        };
    },
    mounted() {
        this.filterData('today', true);

        if (this.isAuthenticated && this.isRole == 'manager' && this.currentSite?._id && this.StateIsAbonnementActif) {
            this.getChffireAffaires();
        }
    },
    methods: {
        ...mapActions(["chiffreAffaires"]),
        filterData(option, selectOption) {
            const timeZone = 'Europe/Paris';
            const currentDate = DateTime.now().setZone(timeZone).setZone('UTC+0');
            let dateFrom, dateTo;
            let dateNFrom, dateNTo;

            const [fromHour, fromMinutes] = this.parametrages?.calculeTicket?.TimeDebut?.split(':') ?? ['00', '00']
            const [toHour, toMinute] = this.parametrages?.calculeTicket?.TimeFin?.split(':') ?? ['23', '59']

            const startTime = { hour: fromHour, minute: fromMinutes }
            const endTime = { hour: toHour, minute: toMinute }

            switch (option) {
                case 'today':
                    dateFrom = currentDate.set(startTime);
                    dateTo = dateFrom.set(endTime);

                    dateNFrom = currentDate.minus({ year: 1 }).set(startTime);
                    dateNTo = dateNFrom.set(endTime);


                    break;
                case "yesterday":
                    dateFrom = currentDate.set(startTime).minus({ day: 1 });
                    dateTo = dateFrom.set(endTime);

                    dateNFrom = currentDate.minus({ year: 1 }).set(startTime).minus({ day: 1 });
                    dateNTo = dateNFrom.set(endTime);


                    break;
                case 'week':

                    // Start from Monday (weekday 1) and end on Sunday (weekday 7)
                    dateFrom = currentDate.startOf('week').set(startTime);
                    dateTo = currentDate.endOf('week').minus({ days: 1 }).set(startTime);
                    dateNFrom = dateFrom.minus({ years: 1 }); //.startOf('week').set(startTime);
                    dateNTo = dateTo.minus({ years: 1 }); //.endOf('week').set(startTime);
                    break;
                case 'previous_week':

                    // Start from Monday of the previous week and end on Sunday of the previous week
                    const startOfPreviousWeek = currentDate.startOf('week').set(startTime).minus({ weeks: 1 });
                    const endOfPreviousWeek = currentDate.endOf('week').set(endTime).minus({ weeks: 1, days: 1 });
                    dateFrom = startOfPreviousWeek;
                    dateTo = endOfPreviousWeek;

                    dateNFrom = dateFrom.minus({ years: 1 });
                    dateNTo = dateTo.minus({ years: 1 });
                    break;

                case 'month':

                    dateFrom = currentDate.startOf('month').set(startTime);
                    dateTo = currentDate.endOf('month').set(endTime);

                    dateNFrom = dateFrom.minus({ years: 1 });
                    dateNTo = dateTo.minus({ years: 1 });


                    break;
                case 'previous_month':
                    dateFrom = currentDate.startOf('month').set(startTime).minus({ months: 1 });
                    dateTo = currentDate.endOf('month').set(endTime).minus({ months: 1 });

                    dateNFrom = dateFrom.minus({ years: 1 });
                    dateNTo = dateTo.minus({ years: 1 });



                    break;
                case 'year':
                    dateFrom = currentDate.startOf('year').set(startTime);
                    dateTo = currentDate.endOf('year').set(endTime);

                    dateNFrom = dateFrom.minus({ years: 1 });
                    dateNTo = dateTo.minus({ years: 1 });


                    break;
                case 'previous_year':

                    dateFrom = currentDate.startOf('year').set(startTime).minus({ years: 1 });
                    dateTo = currentDate.endOf('year').set(endTime).minus({ years: 1 });

                    dateNFrom = dateFrom.minus({ years: 1 });
                    dateNTo = dateTo.minus({ years: 1 });


                    break;
            }

            if (fromHour > 0) {
                dateTo = dateTo.plus({ days: 1 })
                dateNTo = dateNTo.plus({ days: 1 })
            }

            const isodateFrom = DateTime.fromISO(dateFrom);
            const isodateTo = DateTime.fromISO(dateTo);
            const formattedDateFrom = isodateFrom.toISO({ includeOffset: false, suppressMilliseconds: false });
            const formattedDateTo = isodateTo.toISO({ includeOffset: false, suppressMilliseconds: false });

            const isodateNFrom = DateTime.fromISO(dateNFrom);
            const isodateNTo = DateTime.fromISO(dateNTo);
            const formattedNDateFrom = isodateNFrom.toISO({ includeOffset: false, suppressMilliseconds: false });
            const formattedNDateTo = isodateNTo.toISO({ includeOffset: false, suppressMilliseconds: false });

            if (selectOption) {
                this.datePicker.date_n.range = [formattedNDateFrom, formattedNDateTo];
                this.datePicker.date.range = [formattedDateFrom, formattedDateTo];
                this.datePicker.date.from = dateFrom.toFormat('MM-dd-yyyy HH:mm');
                this.datePicker.date.to = dateTo.toFormat('MM-dd-yyyy HH:mm');
                this.datePicker.date_n.from = dateNFrom.toFormat('MM-dd-yyyy HH:mm');
                this.datePicker.date_n.to = dateNTo.toFormat('MM-dd-yyyy HH:mm');
                this.selectedDates = [this.datePicker.date.from, this.datePicker.date.to];
                this.selectedDatesN1 = [this.datePicker.date_n.from, this.datePicker.date_n.to];
                this.datePicker.defaultDates.type = option
            }


            if (!selectOption)
                return [formattedDateFrom, formattedDateTo]

        },
        handleDate(selectedDate) {
            let dateFrom, dateTo;
            let dateNFrom, dateNTo;

            const [fromHour, fromMinutes] = this.parametrages?.calculeTicket?.TimeDebut?.split(':') ?? ['00', '00']
            const [toHour, toMinute] = this.parametrages?.calculeTicket?.TimeFin?.split(':') ?? ['23', '59']


            const from = (this.$dayjs(selectedDate[0]).format('YYYY-MM-DD HH:mm'))
            dateFrom = DateTime.fromFormat(from, 'yyyy-MM-dd HH:mm');
            const to = (this.$dayjs(selectedDate[1]).format('YYYY-MM-DD HH:mm'))
            dateTo = DateTime.fromFormat(to, 'yyyy-MM-dd HH:mm');

            dateNFrom = dateFrom.minus({ year: 1 })
            dateNTo = dateTo.minus({ year: 1 })

            if (fromHour > 0) {
                dateTo = dateTo.plus({ day: 1 })
                dateNTo = dateNTo.plus({ day: 1 })
            }


            this.datePicker.date.from = dateFrom.toFormat('MM-dd-yyyy HH:mm');
            this.datePicker.date.to = dateTo.toFormat('MM-dd-yyyy HH:mm');


            this.datePicker.date_n.from = dateNFrom.toFormat('MM-dd-yyyy HH:mm');
            this.datePicker.date_n.to = dateNTo.toFormat('MM-dd-yyyy HH:mm');

            const isodateFrom = DateTime.fromISO(dateFrom);
            const isodateTo = DateTime.fromISO(dateTo);
            const formattedDateFrom = isodateFrom.toISO({ includeOffset: false, suppressMilliseconds: false });
            const formattedDateTo = isodateTo.toISO({ includeOffset: false, suppressMilliseconds: false });
            this.datePicker.date.range = [formattedDateFrom, formattedDateTo];

            const isodateNFrom = DateTime.fromISO(dateNFrom);
            const isodateNTo = DateTime.fromISO(dateNTo);
            const formattedNDateFrom = isodateNFrom.toISO({ includeOffset: false, suppressMilliseconds: false });
            const formattedNDateTo = isodateNTo.toISO({ includeOffset: false, suppressMilliseconds: false });
            this.datePicker.date_n.range = [formattedNDateFrom, formattedNDateTo];
            this.datePicker.defaultDates.type = null
            this.selectedDatesN1 = [this.datePicker.date_n.from, this.datePicker.date_n.to];
        },
        async getChffireAffaires() {
            const dates = ['today', 'yesterday', 'week', 'previous_week', 'month', 'previous_month', 'year', 'previous_year']
            let self = this
            this.datePicker.defaultDates.list = []
            dates.forEach((element) => {

                this.datePicker.defaultDates.list.push(self.filterData(element, false))
            });
            this.chiffre_affaires = [];
            this.chiffre_affaires = await this.chiffreAffaires({
                url: "entetes/chiffre-affaire",
                vendeur: "vendeur",
                site: this.currentSite?._id ?? null,
                rangeDates: this.datePicker.defaultDates.list
            });
        },

    },
    watch: {

    },
    computed: {
        ...mapState(['datePicker', 'chiffre_affaires']),
        ...mapGetters({
            isAuthenticated: "isAuthenticated",
            isRole: "isRole",
            User: "StateUser",
            BaseUrl: "StateBase",
            res: "StateRes",
            StateIsAbonnementActif: "StateIsAbonnementActif",
            ManagerWithStore: "StateManagerWithStore",
            currentSite: "currentSite",
            parametrages: "StateParametrages",
            listChiffreAffaires: "listChiffreAffaires"
        }),
        // return this.$store.state.user;
        chiffredaffaires() {
            if (this.chiffre_affaires.length > 0) {
                return this.chiffre_affaires;
            }
            return [];
        },
    }
};
</script>
<!-- eslint-disable -->
<style>
.type_ca .btn-info.active,
.type_ca span.active {
    background: blue;
    color: #fff;
}

.type_ca .btn-info {
    padding: 5px 7px;
    cursor: pointer;
    border: 1px solid #d9d9d9;
    font-weight: 500;
    height: 90px;
    margin: 3px;
    position: relative;
}


.type_ca span {
    background: #fff;
    padding: 5px 7px;
    cursor: pointer;
    border: 1px solid #d9d9d9;
    font-weight: 500;
    /* width: 11.9% !important; */
    height: 90px;
    margin: 3px;
    position: relative;
}

.chiffre-afaire {
    position: absolute;
    bottom: 5px;
    width: 100%;
    left: 0;
    font-size: 12px;
}



/**
Responsive */

@media only screen and (min-width:991px) {
    .grid-8 {
        grid-template-columns: repeat(8, 1fr) !important;
        display: grid;
    }
}

@media only screen and (max-width:990px) and (min-width:601px) {
    .grid-8 {
        grid-template-columns: repeat(4, 1fr) !important;
        display: grid;
    }
}

@media only screen and (max-width:600px) {
    .grid-8 {
        grid-template-columns: repeat(2, 1fr) !important;
        display: grid;
    }
}
</style>